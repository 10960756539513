import { default as adminstzWwUCQtg4Meta } from "/tmp/build_2fe399e3/pages/administration/admins.vue?macro=true";
import { default as _91id_93yb9ckiTkUWMeta } from "/tmp/build_2fe399e3/pages/administration/billing/[id].vue?macro=true";
import { default as indexk16XOxwg6vMeta } from "/tmp/build_2fe399e3/pages/administration/billing/index.vue?macro=true";
import { default as bookingsdVUjgwI97xMeta } from "/tmp/build_2fe399e3/pages/administration/bookings.vue?macro=true";
import { default as exports7vqrwIWJE9Meta } from "/tmp/build_2fe399e3/pages/administration/exports.vue?macro=true";
import { default as fidelityxNfGa73q1CMeta } from "/tmp/build_2fe399e3/pages/administration/fidelity.vue?macro=true";
import { default as invoicesZF6isK3LJVMeta } from "/tmp/build_2fe399e3/pages/administration/invoices.vue?macro=true";
import { default as policyVDqp4vfUeaMeta } from "/tmp/build_2fe399e3/pages/administration/organization/policy.vue?macro=true";
import { default as _91id_93LiIOmJ23oDMeta } from "/tmp/build_2fe399e3/pages/administration/payment/[id].vue?macro=true";
import { default as indexW7p0tasmY5Meta } from "/tmp/build_2fe399e3/pages/administration/payment/index.vue?macro=true";
import { default as co2pHzFPB1zLVMeta } from "/tmp/build_2fe399e3/pages/administration/statistics/co2.vue?macro=true";
import { default as indexPDTZTHhyjeMeta } from "/tmp/build_2fe399e3/pages/administration/statistics/index.vue?macro=true";
import { default as index44yZuvvJN3Meta } from "/tmp/build_2fe399e3/pages/administration/teams/[teamId]/index.vue?macro=true";
import { default as invitationx0jZa3VSp4Meta } from "/tmp/build_2fe399e3/pages/administration/teams/[teamId]/invitation.vue?macro=true";
import { default as indexnNGnTUfvgOMeta } from "/tmp/build_2fe399e3/pages/administration/teams/[teamId]/policy/index.vue?macro=true";
import { default as modify4hHXNxT6TSMeta } from "/tmp/build_2fe399e3/pages/administration/teams/[teamId]/policy/modify.vue?macro=true";
import { default as validatorslffg6OxtOAMeta } from "/tmp/build_2fe399e3/pages/administration/teams/[teamId]/validators.vue?macro=true";
import { default as _91teamId_93yRRNkQIq2jMeta } from "/tmp/build_2fe399e3/pages/administration/teams/[teamId].vue?macro=true";
import { default as indexPtlc8j3umHMeta } from "/tmp/build_2fe399e3/pages/administration/teams/index.vue?macro=true";
import { default as trip_45labelsqMtwD5CT1mMeta } from "/tmp/build_2fe399e3/pages/administration/trip-labels.vue?macro=true";
import { default as administrationYQ7ruKtU9uMeta } from "/tmp/build_2fe399e3/pages/administration.vue?macro=true";
import { default as swile1WV58jKeQPMeta } from "/tmp/build_2fe399e3/pages/auth/swile.vue?macro=true";
import { default as indexXRrfctd5ATMeta } from "/tmp/build_2fe399e3/pages/bookings/index.vue?macro=true";
import { default as indexiy7mwB3MvNMeta } from "/tmp/build_2fe399e3/pages/bookings/validator/index.vue?macro=true";
import { default as pending6XsFWZPdx9Meta } from "/tmp/build_2fe399e3/pages/bookings/validator/pending.vue?macro=true";
import { default as _91id_93xrh7HY1UbtMeta } from "/tmp/build_2fe399e3/pages/car/[id].vue?macro=true";
import { default as fidelity1qnZbHHtM7Meta } from "/tmp/build_2fe399e3/pages/fidelity.vue?macro=true";
import { default as exchangeBMPoggyjDuMeta } from "/tmp/build_2fe399e3/pages/flight/[id]/exchange.vue?macro=true";
import { default as faresPhBHMIfnibMeta } from "/tmp/build_2fe399e3/pages/flight/[id]/fares.vue?macro=true";
import { default as indexH2nuLX3BvLMeta } from "/tmp/build_2fe399e3/pages/flight/[id]/index.vue?macro=true";
import { default as forgotfYxgEpZs7mMeta } from "/tmp/build_2fe399e3/pages/forgot.vue?macro=true";
import { default as get_45swile_45appimuorLfOk1Meta } from "/tmp/build_2fe399e3/pages/get-swile-app.vue?macro=true";
import { default as helpLqluYYDfWtMeta } from "/tmp/build_2fe399e3/pages/help.vue?macro=true";
import { default as _91id_93y5SVU3HhuTMeta } from "/tmp/build_2fe399e3/pages/hotel/[id].vue?macro=true";
import { default as _91id_9354gpwhTwi1Meta } from "/tmp/build_2fe399e3/pages/impersonate/[id].vue?macro=true";
import { default as indexyOoRBn1RKSMeta } from "/tmp/build_2fe399e3/pages/index.vue?macro=true";
import { default as _91suffix_93jRG1gx2pNoMeta } from "/tmp/build_2fe399e3/pages/invitation/[suffix].vue?macro=true";
import { default as _91token_93eTLffHkoj3Meta } from "/tmp/build_2fe399e3/pages/invite/[token].vue?macro=true";
import { default as _91id_93IWNwtg74QtMeta } from "/tmp/build_2fe399e3/pages/journeys/[id].vue?macro=true";
import { default as index0U2liSFeZEMeta } from "/tmp/build_2fe399e3/pages/journeys/index.vue?macro=true";
import { default as memberskvwh7LrhQnMeta } from "/tmp/build_2fe399e3/pages/myteam/members.vue?macro=true";
import { default as policyKnQaaF42f6Meta } from "/tmp/build_2fe399e3/pages/myteam/policy.vue?macro=true";
import { default as validatorsRKvu4fYaIAMeta } from "/tmp/build_2fe399e3/pages/myteam/validators.vue?macro=true";
import { default as myteamiebkW7rLhIMeta } from "/tmp/build_2fe399e3/pages/myteam.vue?macro=true";
import { default as new_45usergzdqXyAvHOMeta } from "/tmp/build_2fe399e3/pages/new-user.vue?macro=true";
import { default as passwordHuIr0FhHEJMeta } from "/tmp/build_2fe399e3/pages/password.vue?macro=true";
import { default as indexjRJlEoIF9fMeta } from "/tmp/build_2fe399e3/pages/search/car/[search_id]/index.vue?macro=true";
import { default as indexmPOFDD5USJMeta } from "/tmp/build_2fe399e3/pages/search/car/index.vue?macro=true";
import { default as _91search_id_93gEe0KbrgX9Meta } from "/tmp/build_2fe399e3/pages/search/car/init/[search_id].vue?macro=true";
import { default as _91search_id_93QZcZ8jLYeXMeta } from "/tmp/build_2fe399e3/pages/search/flight/[search_id].vue?macro=true";
import { default as indexRzEXNYNBYRMeta } from "/tmp/build_2fe399e3/pages/search/flight/index.vue?macro=true";
import { default as index876zkGDztZMeta } from "/tmp/build_2fe399e3/pages/search/hotel/[search_id]/index.vue?macro=true";
import { default as _91hotelIds_93eI0jTccD98Meta } from "/tmp/build_2fe399e3/pages/search/hotel/[search_id]/rooms/[hotelIds].vue?macro=true";
import { default as indexCsItpd2CYjMeta } from "/tmp/build_2fe399e3/pages/search/hotel/index.vue?macro=true";
import { default as _91search_id_93A9W7PcXAq8Meta } from "/tmp/build_2fe399e3/pages/search/train/[search_id].vue?macro=true";
import { default as indexPE5uzwjFXtMeta } from "/tmp/build_2fe399e3/pages/search/train/index.vue?macro=true";
import { default as searchrFk8D4zz9FMeta } from "/tmp/build_2fe399e3/pages/search.vue?macro=true";
import { default as indexjdYfMXvTcDMeta } from "/tmp/build_2fe399e3/pages/teams/[teamId]/index.vue?macro=true";
import { default as invitationlUx5npa5ENMeta } from "/tmp/build_2fe399e3/pages/teams/[teamId]/invitation.vue?macro=true";
import { default as policyrf7lLP6fVSMeta } from "/tmp/build_2fe399e3/pages/teams/[teamId]/policy.vue?macro=true";
import { default as validatorsCs79g0lnYkMeta } from "/tmp/build_2fe399e3/pages/teams/[teamId]/validators.vue?macro=true";
import { default as _91teamId_93hqsYvRj0tGMeta } from "/tmp/build_2fe399e3/pages/teams/[teamId].vue?macro=true";
import { default as index5EZUiz5ZlSMeta } from "/tmp/build_2fe399e3/pages/teams/index.vue?macro=true";
import { default as teamsQJQibtOEowMeta } from "/tmp/build_2fe399e3/pages/teams.vue?macro=true";
import { default as indexVowmTmd3RBMeta } from "/tmp/build_2fe399e3/pages/train/[id]/cancel/index.vue?macro=true";
import { default as indexDqfWiISADtMeta } from "/tmp/build_2fe399e3/pages/train/[id]/exchange/changeable-selection/index.vue?macro=true";
import { default as index6lc6NIohTNMeta } from "/tmp/build_2fe399e3/pages/train/[id]/exchange/section-selection/index.vue?macro=true";
import { default as indexMY1okpYg67Meta } from "/tmp/build_2fe399e3/pages/train/[id]/exchange/train-selection/index.vue?macro=true";
import { default as indexWjldAp2URHMeta } from "/tmp/build_2fe399e3/pages/train/[id]/index.vue?macro=true";
import { default as driver_45license1AQRRQJXHLMeta } from "/tmp/build_2fe399e3/pages/users/[id]/driver-license.vue?macro=true";
import { default as fidelitye11KrbXzlGMeta } from "/tmp/build_2fe399e3/pages/users/[id]/fidelity.vue?macro=true";
import { default as indexHr0Z1cHJNTMeta } from "/tmp/build_2fe399e3/pages/users/[id]/index.vue?macro=true";
import { default as passportzkktj22wLcMeta } from "/tmp/build_2fe399e3/pages/users/[id]/passport.vue?macro=true";
import { default as password8hSGr5t3CZMeta } from "/tmp/build_2fe399e3/pages/users/[id]/password.vue?macro=true";
import { default as _91id_93FGf6x23P5XMeta } from "/tmp/build_2fe399e3/pages/users/[id].vue?macro=true";
import { default as indexlAlGpsAAbOMeta } from "/tmp/build_2fe399e3/pages/users/index.vue?macro=true";
import { default as vouchersEstPFSYTsGMeta } from "/tmp/build_2fe399e3/pages/vouchers.vue?macro=true";
import { default as component_45stubSCMmvDOeTRMeta } from "/tmp/build_2fe399e3/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubSCMmvDOeTR } from "/tmp/build_2fe399e3/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "administration",
    path: "/administration",
    meta: administrationYQ7ruKtU9uMeta || {},
    component: () => import("/tmp/build_2fe399e3/pages/administration.vue"),
    children: [
  {
    name: "administration-admins",
    path: "admins",
    component: () => import("/tmp/build_2fe399e3/pages/administration/admins.vue")
  },
  {
    name: "administration-billing-id",
    path: "billing/:id()",
    component: () => import("/tmp/build_2fe399e3/pages/administration/billing/[id].vue")
  },
  {
    name: "administration-billing",
    path: "billing",
    component: () => import("/tmp/build_2fe399e3/pages/administration/billing/index.vue")
  },
  {
    name: "administration-bookings",
    path: "bookings",
    component: () => import("/tmp/build_2fe399e3/pages/administration/bookings.vue")
  },
  {
    name: "administration-exports",
    path: "exports",
    component: () => import("/tmp/build_2fe399e3/pages/administration/exports.vue")
  },
  {
    name: "administration-fidelity",
    path: "fidelity",
    component: () => import("/tmp/build_2fe399e3/pages/administration/fidelity.vue")
  },
  {
    name: "administration-invoices",
    path: "invoices",
    component: () => import("/tmp/build_2fe399e3/pages/administration/invoices.vue")
  },
  {
    name: "administration-organization-policy",
    path: "organization/policy",
    component: () => import("/tmp/build_2fe399e3/pages/administration/organization/policy.vue")
  },
  {
    name: "administration-payment-id",
    path: "payment/:id()",
    component: () => import("/tmp/build_2fe399e3/pages/administration/payment/[id].vue")
  },
  {
    name: "administration-payment",
    path: "payment",
    component: () => import("/tmp/build_2fe399e3/pages/administration/payment/index.vue")
  },
  {
    name: "administration-statistics-co2",
    path: "statistics/co2",
    component: () => import("/tmp/build_2fe399e3/pages/administration/statistics/co2.vue")
  },
  {
    name: "administration-statistics",
    path: "statistics",
    component: () => import("/tmp/build_2fe399e3/pages/administration/statistics/index.vue")
  },
  {
    name: _91teamId_93yRRNkQIq2jMeta?.name,
    path: "teams/:teamId()",
    component: () => import("/tmp/build_2fe399e3/pages/administration/teams/[teamId].vue"),
    children: [
  {
    name: "administration-teams-teamId",
    path: "",
    meta: index44yZuvvJN3Meta || {},
    component: () => import("/tmp/build_2fe399e3/pages/administration/teams/[teamId]/index.vue")
  },
  {
    name: "administration-teams-teamId-invitation",
    path: "invitation",
    component: () => import("/tmp/build_2fe399e3/pages/administration/teams/[teamId]/invitation.vue")
  },
  {
    name: "administration-teams-teamId-policy",
    path: "policy",
    component: () => import("/tmp/build_2fe399e3/pages/administration/teams/[teamId]/policy/index.vue")
  },
  {
    name: "administration-teams-teamId-policy-modify",
    path: "policy/modify",
    meta: modify4hHXNxT6TSMeta || {},
    component: () => import("/tmp/build_2fe399e3/pages/administration/teams/[teamId]/policy/modify.vue")
  },
  {
    name: "administration-teams-teamId-validators",
    path: "validators",
    component: () => import("/tmp/build_2fe399e3/pages/administration/teams/[teamId]/validators.vue")
  }
]
  },
  {
    name: "administration-teams",
    path: "teams",
    component: () => import("/tmp/build_2fe399e3/pages/administration/teams/index.vue")
  },
  {
    name: "administration-trip-labels",
    path: "trip-labels",
    component: () => import("/tmp/build_2fe399e3/pages/administration/trip-labels.vue")
  }
]
  },
  {
    name: "auth-swile",
    path: "/auth/swile",
    meta: swile1WV58jKeQPMeta || {},
    component: () => import("/tmp/build_2fe399e3/pages/auth/swile.vue")
  },
  {
    name: "bookings",
    path: "/bookings",
    component: () => import("/tmp/build_2fe399e3/pages/bookings/index.vue")
  },
  {
    name: "bookings-validator",
    path: "/bookings/validator",
    meta: indexiy7mwB3MvNMeta || {},
    component: () => import("/tmp/build_2fe399e3/pages/bookings/validator/index.vue")
  },
  {
    name: "bookings-validator-pending",
    path: "/bookings/validator/pending",
    meta: pending6XsFWZPdx9Meta || {},
    component: () => import("/tmp/build_2fe399e3/pages/bookings/validator/pending.vue")
  },
  {
    name: "car-id",
    path: "/car/:id()",
    component: () => import("/tmp/build_2fe399e3/pages/car/[id].vue")
  },
  {
    name: "fidelity",
    path: "/fidelity",
    component: () => import("/tmp/build_2fe399e3/pages/fidelity.vue")
  },
  {
    name: "flight-id-exchange",
    path: "/flight/:id()/exchange",
    component: () => import("/tmp/build_2fe399e3/pages/flight/[id]/exchange.vue")
  },
  {
    name: "flight-id-fares",
    path: "/flight/:id()/fares",
    component: () => import("/tmp/build_2fe399e3/pages/flight/[id]/fares.vue")
  },
  {
    name: "flight-id",
    path: "/flight/:id()",
    component: () => import("/tmp/build_2fe399e3/pages/flight/[id]/index.vue")
  },
  {
    name: "forgot",
    path: "/forgot",
    meta: forgotfYxgEpZs7mMeta || {},
    component: () => import("/tmp/build_2fe399e3/pages/forgot.vue")
  },
  {
    name: "get-swile-app",
    path: "/get-swile-app",
    meta: get_45swile_45appimuorLfOk1Meta || {},
    component: () => import("/tmp/build_2fe399e3/pages/get-swile-app.vue")
  },
  {
    name: "help",
    path: "/help",
    component: () => import("/tmp/build_2fe399e3/pages/help.vue")
  },
  {
    name: "hotel-id",
    path: "/hotel/:id()",
    component: () => import("/tmp/build_2fe399e3/pages/hotel/[id].vue")
  },
  {
    name: "impersonate-id",
    path: "/impersonate/:id()",
    component: () => import("/tmp/build_2fe399e3/pages/impersonate/[id].vue")
  },
  {
    name: "index",
    path: "/",
    meta: indexyOoRBn1RKSMeta || {},
    component: () => import("/tmp/build_2fe399e3/pages/index.vue")
  },
  {
    name: "invitation-suffix",
    path: "/invitation/:suffix()",
    meta: _91suffix_93jRG1gx2pNoMeta || {},
    component: () => import("/tmp/build_2fe399e3/pages/invitation/[suffix].vue")
  },
  {
    name: "invite-token",
    path: "/invite/:token()",
    meta: _91token_93eTLffHkoj3Meta || {},
    component: () => import("/tmp/build_2fe399e3/pages/invite/[token].vue")
  },
  {
    name: "journeys-id",
    path: "/journeys/:id()",
    component: () => import("/tmp/build_2fe399e3/pages/journeys/[id].vue")
  },
  {
    name: "journeys",
    path: "/journeys",
    component: () => import("/tmp/build_2fe399e3/pages/journeys/index.vue")
  },
  {
    name: "myteam",
    path: "/myteam",
    component: () => import("/tmp/build_2fe399e3/pages/myteam.vue"),
    children: [
  {
    name: "myteam-members",
    path: "members",
    component: () => import("/tmp/build_2fe399e3/pages/myteam/members.vue")
  },
  {
    name: "myteam-policy",
    path: "policy",
    component: () => import("/tmp/build_2fe399e3/pages/myteam/policy.vue")
  },
  {
    name: "myteam-validators",
    path: "validators",
    component: () => import("/tmp/build_2fe399e3/pages/myteam/validators.vue")
  }
]
  },
  {
    name: "new-user",
    path: "/new-user",
    meta: new_45usergzdqXyAvHOMeta || {},
    component: () => import("/tmp/build_2fe399e3/pages/new-user.vue")
  },
  {
    name: "password",
    path: "/password",
    meta: passwordHuIr0FhHEJMeta || {},
    component: () => import("/tmp/build_2fe399e3/pages/password.vue")
  },
  {
    name: "search",
    path: "/search",
    component: () => import("/tmp/build_2fe399e3/pages/search.vue"),
    children: [
  {
    name: "search-car-search_id",
    path: "car/:search_id()",
    component: () => import("/tmp/build_2fe399e3/pages/search/car/[search_id]/index.vue")
  },
  {
    name: "search-car",
    path: "car",
    component: () => import("/tmp/build_2fe399e3/pages/search/car/index.vue")
  },
  {
    name: "search-car-init-search_id",
    path: "car/init/:search_id()",
    component: () => import("/tmp/build_2fe399e3/pages/search/car/init/[search_id].vue")
  },
  {
    name: "search-flight-search_id",
    path: "flight/:search_id()",
    component: () => import("/tmp/build_2fe399e3/pages/search/flight/[search_id].vue")
  },
  {
    name: "search-flight",
    path: "flight",
    component: () => import("/tmp/build_2fe399e3/pages/search/flight/index.vue")
  },
  {
    name: "search-hotel-search_id",
    path: "hotel/:search_id()",
    component: () => import("/tmp/build_2fe399e3/pages/search/hotel/[search_id]/index.vue")
  },
  {
    name: "search-hotel-search_id-rooms-hotelIds",
    path: "hotel/:search_id()/rooms/:hotelIds()",
    component: () => import("/tmp/build_2fe399e3/pages/search/hotel/[search_id]/rooms/[hotelIds].vue")
  },
  {
    name: "search-hotel",
    path: "hotel",
    component: () => import("/tmp/build_2fe399e3/pages/search/hotel/index.vue")
  },
  {
    name: "search-train-search_id",
    path: "train/:search_id()",
    component: () => import("/tmp/build_2fe399e3/pages/search/train/[search_id].vue")
  },
  {
    name: "search-train",
    path: "train",
    component: () => import("/tmp/build_2fe399e3/pages/search/train/index.vue")
  }
]
  },
  {
    name: teamsQJQibtOEowMeta?.name,
    path: "/teams",
    meta: teamsQJQibtOEowMeta || {},
    component: () => import("/tmp/build_2fe399e3/pages/teams.vue"),
    children: [
  {
    name: _91teamId_93hqsYvRj0tGMeta?.name,
    path: ":teamId()",
    component: () => import("/tmp/build_2fe399e3/pages/teams/[teamId].vue"),
    children: [
  {
    name: "teams-teamId",
    path: "",
    component: () => import("/tmp/build_2fe399e3/pages/teams/[teamId]/index.vue")
  },
  {
    name: "teams-teamId-invitation",
    path: "invitation",
    component: () => import("/tmp/build_2fe399e3/pages/teams/[teamId]/invitation.vue")
  },
  {
    name: "teams-teamId-policy",
    path: "policy",
    component: () => import("/tmp/build_2fe399e3/pages/teams/[teamId]/policy.vue")
  },
  {
    name: "teams-teamId-validators",
    path: "validators",
    component: () => import("/tmp/build_2fe399e3/pages/teams/[teamId]/validators.vue")
  }
]
  },
  {
    name: "teams",
    path: "",
    component: () => import("/tmp/build_2fe399e3/pages/teams/index.vue")
  }
]
  },
  {
    name: "train-id-cancel",
    path: "/train/:id()/cancel",
    component: () => import("/tmp/build_2fe399e3/pages/train/[id]/cancel/index.vue")
  },
  {
    name: "train-id-exchange-changeable-selection",
    path: "/train/:id()/exchange/changeable-selection",
    component: () => import("/tmp/build_2fe399e3/pages/train/[id]/exchange/changeable-selection/index.vue")
  },
  {
    name: "train-id-exchange-section-selection",
    path: "/train/:id()/exchange/section-selection",
    component: () => import("/tmp/build_2fe399e3/pages/train/[id]/exchange/section-selection/index.vue")
  },
  {
    name: "train-id-exchange-train-selection",
    path: "/train/:id()/exchange/train-selection",
    component: () => import("/tmp/build_2fe399e3/pages/train/[id]/exchange/train-selection/index.vue")
  },
  {
    name: "train-id",
    path: "/train/:id()",
    component: () => import("/tmp/build_2fe399e3/pages/train/[id]/index.vue")
  },
  {
    name: _91id_93FGf6x23P5XMeta?.name,
    path: "/users/:id()",
    meta: _91id_93FGf6x23P5XMeta || {},
    component: () => import("/tmp/build_2fe399e3/pages/users/[id].vue"),
    children: [
  {
    name: "users-id-driver-license",
    path: "driver-license",
    component: () => import("/tmp/build_2fe399e3/pages/users/[id]/driver-license.vue")
  },
  {
    name: "users-id-fidelity",
    path: "fidelity",
    component: () => import("/tmp/build_2fe399e3/pages/users/[id]/fidelity.vue")
  },
  {
    name: "users-id",
    path: "",
    component: () => import("/tmp/build_2fe399e3/pages/users/[id]/index.vue")
  },
  {
    name: "users-id-passport",
    path: "passport",
    component: () => import("/tmp/build_2fe399e3/pages/users/[id]/passport.vue")
  },
  {
    name: "users-id-password",
    path: "password",
    component: () => import("/tmp/build_2fe399e3/pages/users/[id]/password.vue")
  }
]
  },
  {
    name: "users",
    path: "/users",
    component: () => import("/tmp/build_2fe399e3/pages/users/index.vue")
  },
  {
    name: "vouchers",
    path: "/vouchers",
    component: () => import("/tmp/build_2fe399e3/pages/vouchers.vue")
  },
  {
    name: "teams-vouchers",
    path: "/teams-vouchers",
    component: () => import("/tmp/build_2fe399e3/pages/vouchers.vue")
  },
  {
    name: component_45stubSCMmvDOeTRMeta?.name,
    path: "/metrics",
    component: component_45stubSCMmvDOeTR
  },
  {
    name: component_45stubSCMmvDOeTRMeta?.name,
    path: "/en",
    component: component_45stubSCMmvDOeTR
  },
  {
    name: component_45stubSCMmvDOeTRMeta?.name,
    path: "/fr",
    component: component_45stubSCMmvDOeTR
  },
  {
    name: component_45stubSCMmvDOeTRMeta?.name,
    path: "/pt-BR",
    component: component_45stubSCMmvDOeTR
  },
  {
    name: component_45stubSCMmvDOeTRMeta?.name,
    path: "/en/:pathMatch(.*)",
    component: component_45stubSCMmvDOeTR
  },
  {
    name: component_45stubSCMmvDOeTRMeta?.name,
    path: "/fr/:pathMatch(.*)",
    component: component_45stubSCMmvDOeTR
  },
  {
    name: component_45stubSCMmvDOeTRMeta?.name,
    path: "/pt-BR/:pathMatch(.*)",
    component: component_45stubSCMmvDOeTR
  }
]