import unhead_KgADcZ0jPj from "/tmp/build_2fe399e3/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/tmp/build_2fe399e3/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/tmp/build_2fe399e3/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/tmp/build_2fe399e3/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/tmp/build_2fe399e3/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/tmp/build_2fe399e3/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/tmp/build_2fe399e3/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/tmp/build_2fe399e3/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_ksq7gplbDw from "/tmp/build_2fe399e3/node_modules/@twicpics/components/nuxt3/plugin.js";
import switch_locale_path_ssr_8BKffvaEi5 from "/tmp/build_2fe399e3/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.mjs";
import i18n_yfWm7jX06p from "/tmp/build_2fe399e3/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import _01_vuex_UHKIi9iGLx from "/tmp/build_2fe399e3/plugins/01.vuex.ts";
import _02_sentry_client_sABltX8K3S from "/tmp/build_2fe399e3/plugins/02.sentry.client.ts";
import _03_ofetch_auth_CP455zqtRo from "/tmp/build_2fe399e3/plugins/03.ofetch-auth.ts";
import _04_ofetch_api_g5pmLFR2x7 from "/tmp/build_2fe399e3/plugins/04.ofetch-api.ts";
import _05_vue3_toastify_mD1i8eeTCD from "/tmp/build_2fe399e3/plugins/05.vue3-toastify.ts";
import _06_iframe_jrnQKxpQ8X from "/tmp/build_2fe399e3/plugins/06.iframe.ts";
import careWidget_client_mxJBDSKOyP from "/tmp/build_2fe399e3/plugins/careWidget.client.ts";
import delighted_client_KUJTcWAZuJ from "/tmp/build_2fe399e3/plugins/delighted.client.js";
import filters_VNgPjx6zHj from "/tmp/build_2fe399e3/plugins/filters.ts";
import firebase_Wjz9XrZo01 from "/tmp/build_2fe399e3/plugins/firebase.ts";
import floating_vue_BiOD74u9sH from "/tmp/build_2fe399e3/plugins/floating-vue.ts";
import hotjar_client_khHI17zbiJ from "/tmp/build_2fe399e3/plugins/hotjar.client.js";
import intercom_client_sjSaVpgP3i from "/tmp/build_2fe399e3/plugins/intercom.client.js";
import maska_UHaKf2z1iQ from "/tmp/build_2fe399e3/plugins/maska.ts";
import okarito_utils_TvwpFukIse from "/tmp/build_2fe399e3/plugins/okarito-utils.js";
import slider_client_6xUBjfQdZi from "/tmp/build_2fe399e3/plugins/slider.client.ts";
import theme_zupea9J243 from "/tmp/build_2fe399e3/plugins/theme.ts";
import vcalendar_client_OtyDXzq1TM from "/tmp/build_2fe399e3/plugins/vcalendar.client.ts";
import vue_virtual_scroller_O7lMY10m40 from "/tmp/build_2fe399e3/plugins/vue-virtual-scroller.ts";
import vue3_lottie_client_xXNcInN2H5 from "/tmp/build_2fe399e3/plugins/vue3-lottie.client.ts";
import vue3_tags_input_client_lSpe8HH2we from "/tmp/build_2fe399e3/plugins/vue3-tags-input.client.js";
export default [
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_ksq7gplbDw,
  switch_locale_path_ssr_8BKffvaEi5,
  i18n_yfWm7jX06p,
  _01_vuex_UHKIi9iGLx,
  _02_sentry_client_sABltX8K3S,
  _03_ofetch_auth_CP455zqtRo,
  _04_ofetch_api_g5pmLFR2x7,
  _05_vue3_toastify_mD1i8eeTCD,
  _06_iframe_jrnQKxpQ8X,
  careWidget_client_mxJBDSKOyP,
  delighted_client_KUJTcWAZuJ,
  filters_VNgPjx6zHj,
  firebase_Wjz9XrZo01,
  floating_vue_BiOD74u9sH,
  hotjar_client_khHI17zbiJ,
  intercom_client_sjSaVpgP3i,
  maska_UHaKf2z1iQ,
  okarito_utils_TvwpFukIse,
  slider_client_6xUBjfQdZi,
  theme_zupea9J243,
  vcalendar_client_OtyDXzq1TM,
  vue_virtual_scroller_O7lMY10m40,
  vue3_lottie_client_xXNcInN2H5,
  vue3_tags_input_client_lSpe8HH2we
]